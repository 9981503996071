/**
 *
 * Main entry point of the app
 *
 */

import { ThemeProvider } from '@mui/material'
import React from 'react'
import { theme } from './theme/theme'
import { CssBaseline } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import NotFound from './routes/not-found'
import { I18nextProvider } from 'react-i18next'
import { UserProvider } from './contexts/user-context'
import i18next from './translations/config'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import NeedsAuthentication from './components/common/needs-authentication'
import Dashboard from './routes/dashboard'
import Login from './components/common/login'

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <I18nextProvider i18n={i18next}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18next.language}>
          <UserProvider>
            <Routes>
              {/* Main */}
              <Route
                path="/*"
                element={
                  <NeedsAuthentication unAuthenticatedElement={<Login />}>
                    <Dashboard />
                  </NeedsAuthentication>
                }
              />

              {/* Not Found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </UserProvider>
        </LocalizationProvider>
      </I18nextProvider>
    </ThemeProvider>
  )
}

export default App
